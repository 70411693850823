.discount-scroll-container {
  width: 100%;
  display: flex;
  height: var(--SeventyVh);
  background-color: rgb(248, 250, 252);
}
.discount-scroll-text-container {
  width: 50%;
  position: relative;
  height: var(--SeventyVh);
  padding: 0px 40px 0px 0px;
}
.discount-scroll-text {
  width: 100%;
  position: absolute;
  top: 44%;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 20px;
}
.discount-scroll-animation {
  width: 50%;
  display: flex;
}
.discount-scroll-animation h2 {
  color: rgb(194, 200, 210);
}
@media (max-width: 768px) {
  .discount-scroll-container {
    height: 35vh;
  }
  .discount-scroll-text-container {
    height: 35vh;
  }
}
@media (max-width: 600px) {
  .discount-scroll-container {
    height: 40vh;
  }
  .discount-scroll-text-container {
    height: 40vh;
    padding: 0px 0px 0px 0px;
  }
  .discount-scroll-text a {
    display: none;
  }
}
