.home-child-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-story-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--container-padding);
  padding-top: 30px;
  padding-bottom: 30px;
}
.terms-container {
  padding: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-green);
  padding: var(--container-padding);
}
.terms-wrapper {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-top: 20px;
  margin-top: 20px;
  background-color: #f8fafc;
  border-radius: 10px;
}
.term-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
}
.term-container h3 {
  font-size: 25px;
  margin-bottom: 5px;
}
.about-story-container h2 {
  font-size: 50px;
}
.faq-container {
  width: 100%;
  min-height: 41vh;
  display: flex;
  flex-direction: column;
  padding: 60px 80px;
}

.about-container {
  width: 100%;
  height: 25vh;
  min-height: 205px;
  display: flex;
  background: linear-gradient(270deg, #0f172a, #173b92);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
  padding: 105px 40px 40px 40px;
}

.about-child-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}

.about-text {
  display: flex;
  width: 100%;
  text-align: center;
  color: var(--white);
}
@media (max-width: 1024px) {
  .faq-container h3 {
    font-size: 25px;
  }
  .faq-container h4 {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .text {
    padding: 0px 0px 50px 0px;
  }
  .logo {
    width: 100%;
    align-items: center;
  }
  .logo-style {
    height: 100%;
    width: 45%;
  }
  .text {
    width: 100%;
    text-align: center;
  }
  .charity-data-container-child h4 {
    font-size: 20px;
  }
  .charity-data-container-child p {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .home-child-container {
    background-size: 350px 350px;
  }
  .logo {
    margin-top: 100px;
  }
  .logo-style {
    height: 100%;
    width: 60%;
  }
  .about-container {
    height: 100%;
    padding: 80px 40px 40px 40px;
  }
  .about-story-container {
    width: 100%;
    padding: 40px 40px;
  }
  .about-story-container h2 {
    font-size: 35px;
  }
  .about-story-container h2 {
    font-size: 35px;
  }
  .faq-container {
    width: 100%;
    padding: 40px 20px;
  }
  .charity-container {
    flex-direction: column;
  }
  .charity-container-child {
    width: 100%;
  }
  .charity-data-container {
    flex-direction: column;
  }
  .charity-data-container-child {
    width: 100%;
  }
  .quote {
    display: flex;
    width: 100%;
    color: #434343;
    font-size: 55px;
    height: 40px;
  }
}
