.fundraising-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 500px;
}
.fundraising-wrapper-top {
  width: 100%;
  height: 20vh;
  max-height: 250px;
  background-color: #f8fafc;
}
.fundraising-wrapper-bottom {
  width: 100%;
  height: 20vh;
  max-height: 250px;
  animation: AnimationName 10s ease infinite;
  background: linear-gradient(
    270deg,
    var(--color-secondary),
    var(--dark-green)
  );
  background-size: 400% 400%;
}

.fundraising-wrapper {
  max-width: calc(1500px - 380px);
  width: calc(100% - 380px);
  max-height: 400px;
  height: 40vh;
  display: flex;
  background-color: #f8fafc;
  position: absolute;
  border-bottom-left-radius: 60px !important;
  -webkit-border-bottom-left-radius: 60px !important;
  -moz-border-radius-bottomleft: 60px !important;
  -webkit-border-top-right-radius: 60px !important;
  -moz-border-radius-topright: 60px !important;
  border-top-right-radius: 60px !important;
  -webkit-box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.15);
  border: 3px solid var(--dark-green);
}
.fundraising-container-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fundraising-estimate-image {
  width: 75%;
}
.fundraising-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fundraising-text-container h3 {
  font-size: 35px;
  font-weight: 600;
  margin: 0px 20px;
}

@media (max-width: 1024px) {
  .fundraising-wrapper {
    max-width: calc(1500px - 180px);
    width: calc(100% - 180px);
    max-height: 400px;
    height: 35vh;
  }
  .fundraising-text-container h3 {
    font-size: 30px;
    margin: 0px 20px;
  }
  .fundraising-wrapper-top {
    height: 20vh;
  }
  .fundraising-wrapper-bottom {
    height: 20vh;
  }
}
@media (max-width: 768px) {
  .fundraising-wrapper {
    max-width: calc(1500px - 60px);
    width: calc(100% - 60px);
    max-height: 400px;
    height: 22vh;
  }
  .fundraising-text-container h3 {
    font-size: 25px;
    margin: 0px 10px;
  }
  .fundraising-wrapper-top {
    height: 11vh;
  }
  .fundraising-wrapper-bottom {
    height: 11vh;
  }
}
@media (max-width: 600px) {
  .fundraising-estimate-image {
    width: 32%;
    margin-bottom: 20px;
  }
  .fundraising-wrapper-top {
    height: 25vh;
  }
  .fundraising-wrapper-bottom {
    height: 25vh;
  }
  .fundraising-wrapper {
    width: 89%;
    height: 50vh;
    flex-direction: column;
    padding: 20px;
  }
}
