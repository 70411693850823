.full-width-card-container-website {
  width: 100%;
  max-height: 100%;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  text-decoration: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-color: var(--white);
}

.full-width-card-container:hover {
  box-shadow: 0px 3px 0 0 var(--dark-green);
}

.full-width-description-text {
  margin: 0px !important;
  font-size: 15px !important;
  color: #0f172a !important;
  margin-bottom: 7px !important;
  padding-left: 5px;
}

.full-width-description-expires {
  margin: 0px !important;
  font-weight: 600 !important;
  color: #999999 !important;
  text-decoration: none !important;
  margin-bottom: 7px !important;
}

.full-width-discount-container {
  width: 100%;
  display: flex;
}

.discount-container {
  width: 50%;
  display: flex;
}
.discount-code {
  border: 2px dashed var(--dark-green);
  padding: 5px 10px;
  border-radius: 5px;
}

.discount-container p {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  color: var(--dark-green);
}

.full-width-button-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.full-width-button {
  display: flex;
  padding: 5px 10px;
  color: var(--color-secondary);
  background-color: transparent;
  align-self: flex-end;
  border-radius: 20px;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(163, 163, 163, 1);
  -moz-box-shadow: 0px 1px 2px 1px rgba(163, 163, 163, 1);
  box-shadow: 0px 1px 2px 1px rgba(163, 163, 163, 1);
}

.error {
  background-color: rgb(214, 0, 0);
}

.full-width-button p {
  margin: 0px;
  font-size: 15px;
}

.visit-card-container {
  width: 170px;
  display: flex;
  align-items: center;
  border-top: 0.5px solid #eee;
  border-bottom: 0.5px solid #eee;
  padding: 10px 10px;
  cursor: pointer;
  justify-content: center;
}

.visit-card-container:hover {
  box-shadow: 0px 3px 0 0 var(--dark-green);
}

.visit-card-image {
  width: auto;
  height: 30px;
}

.visit-card-button-container {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}

.visit-card-button-container h3 {
  color: var(--dark-gray);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}

.website-card-container {
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid var(--color-secondary);
  padding: 15px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: -1.5px -1.5px 0 0 var(--dark-green);
  margin-bottom: 8px;
  border-radius: 12px;
}

.website-card-container:hover {
  box-shadow: -4px -4px 0 0 var(--dark-green);
}
.website-card-image-container {
  width: 35%;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.website-card-image {
  max-width: 180px;
  height: 40px;
}

.website-card-title-container {
  width: 40%;
  padding-left: 6px;
}

.website-card-discount-container {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.website-card-title-container h3 {
  color: var(--dark-green);
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
}

.website-card-title-discount-container {
  display: flex;
  width: 65%;
  justify-content: space-between;
  align-items: center;
}

.website-card-discount-count {
  min-width: 75px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--dark-green);
  padding: 2px;
  border-radius: 5px;
  color: var(--dark-green);
}

@media (max-width: 500px) {
  .website-card-container {
    padding: 8px;
  }
  .website-card-image-container {
    display: block;
    padding-left: 8px;
  }
  .website-card-discount-count {
    min-width: 50px;
  }
  .website-card-image {
    max-width: 70px;
    height: auto;
  }
}
