.merchant-container {
  width: 100%;
  display: flex;
  height: var(--EightyVh);
  background-color: #f8fafc;
}

.merchant-text-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  align-items: flex-end;
  padding-right: 20px;
}

.merchant-text-container h2 {
  color: var(--dark-green);
}

.merchant-text-container h1 {
  font-size: 8.5vw;
  line-height: 1;
  color: var(--dark-green);
}

.merchant-animation-container {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 0px 70px;
}

.merchant-card {
  position: absolute;
  width: 285px;
  height: 285px;
  border-radius: 20px;
  padding: 10px;
  background-position: center;
  background-size: 285px;
  background-size: cover;
}

@media (max-width: 1024px) {
  .merchant-container {
    height: 62vh;
    padding-bottom: 60px;
  }
  .merchant-text-container {
    width: 45%;
    padding-right: 0px;
    justify-content: flex-end;
  }
  .merchant-animation-container {
    width: 55%;
    justify-content: flex-end;
  }
  .merchant-card {
    width: 250px;
    height: 250px;
    background-size: 250px;
  }
}

@media (max-width: 768px) {
  .merchant-container {
    height: 40vh;
  }
  .merchant-card {
    width: 225px;
    height: 225px;
    background-size: 225px;
  }
  .merchant-text-container {
    width: 40%;
  }
  .merchant-animation-container {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .merchant-container {
    height: 72vh;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .merchant-card {
    width: 200px;
    height: 200px;
    background-size: 200px;
  }
  .merchant-text-container {
    width: 100%;
    padding-right: 0px;
    align-items: center;
    margin-bottom: 30px;
  }
  .merchant-animation-container {
    width: 100%;
    padding: 0px;
    justify-content: flex-end;
    align-items: center;
    height: 80%;
  }
}

