.ai-container {
  overflow: hidden;
  width: 100%;
  height: var(--EightyVh);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: var(--container-padding);
}
.ai-content-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ai-text-container {
  width: 50%;
  height: 100%;
}
.ai-video-container {
  position: relative;
  height: 450px !important;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark-green);
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
}
.ai-header-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ai-header-container h3 {
  margin-bottom: 5px;
}
.ai-header-tab-select {
  color: #fff;
}
.ai-header-tab-unselected {
  color: var(--color-secondary);
}
.ai-header-prompt-container {
  display: flex;
  justify-content: center;
  margin: 5px 0px;
}
.ai-header-prompt-container img {
  margin: 10px 0px 0px 20px;
}
@keyframes slideInLeftToRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in-left-to-right {
  animation: slideInLeftToRight 0.4s ease-out;
}
.ai-video video {
  height: 500px !important;
  border-radius: 20px;
  border: 4px solid #fff;
  box-shadow: 0px 0px 25px 9px rgba(161, 161, 161, 0.2);
}

@media (max-width: 768px) {
  .ai-container {
    height: 50vh;
  }
  .ai-text-container {
    padding: 0px 30px 0px 0px;
  }
  .ai-video-container {
    height: 350px !important;
  }
  .ai-video video {
    height: 400px !important;
  }
}
@media (max-width: 600px) {
  .ai-container {
    height: 100%;
    margin: 10px 0px 30px 0px;
  }
  .ai-content-container {
    flex-direction: column-reverse;
  }
  .ai-text-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
  }
  .ai-header-container {
    align-items: center;
  }
  .ai-video-container {
    height: 275px !important;
    width: 80%;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-bottom: 30px;
  }
  .ai-video video {
    height: 250px !important;
  }
  .slide-in-left-to-right {
    display: none;
  }
  .ai-header-prompt-container {
    margin: 0px;
  }
}
