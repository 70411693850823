.graph-parent-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.graph-parent-wrapper {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}
.graph-child--graph-container {
  width: 100%;
  max-height: 100%;
  min-height: 70vh;
 
  margin-right: 20px;
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}
.graph-child--buttons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 15%;
  height: 100%;
  min-height: 70vh;
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.graph-type-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 130px;
  border-radius: 20px;
  margin: 20px;
  color: rgb(132, 132, 132);
  padding: 10px 0px 0px 0px;
}
.graph-top-container {
  width: 100%;
  display: flex;
  padding: 20px 20px;
}
.graph-bottom-container {
  width: 100%;
  display: flex;
  padding: 20px 20px;
}
.graph-total-container {
  width: 100%;
  color: #fff;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px 0px 8px 0px;
}

.total {
  display: flex;
  justify-content: center;
  color: var(--dark-green);
  background-color: var(--color-primary);
  font-size: 20px;
  /* border: 2px solid var(--dark-green); */
}

.download {
  display: flex;
  justify-content: center;
  color: var(--color-primary);
  background-color: var(--dark-green);
  font-size: 20px;
}

.partner {
  display: flex;
  justify-content: center;
  background-color: var(--dark-green);
  color: #fff;
}
.charity {
  display: flex;
  justify-content: center;
  background-color: var(--color-secondary);
  color: #fff;
}
