.organization-scroll-container {
  width: 100%;
  display: flex;
  height: var(--FortyVh);
  background-color: var(--color-secondary);
}
.organization-scroll-text-container {
  width: 45%;
  position: relative;
  height: var(--FortyVh);
  padding: 0px 40px 0px 0px;
}
.organization-scroll-text {
  width: 100%;
  position: absolute;
  top: 42%;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 20px;
}
.organization-scroll-animation {
  width: 55%;
  display: flex;
}
.organization-scroll-text h3 {
  font-size: 50px;
}
.organization-scroll-animation h3 {
  color: rgba(194, 200, 210, 0.448);
  font-size: 50px;
}

@media (max-width: 1024px) {
  .organization-scroll-text h3 {
    font-size: 40px;
  }
  .organization-scroll-animation h3 {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .organization-scroll-container {
    height: 25vh;
  }
  .organization-scroll-text-container {
    height: 25vh;
  }
  .organization-scroll-text h3 {
    font-size: 30px;
  }
  .organization-scroll-animation h3 {
    font-size: 30px;
  }
}

@media (max-width: 700px) {
  .organization-scroll-container {
    flex-direction: column;
    height: 40vh;
  }
  .organization-scroll-text-container {
    width: 100%;
    height: 40vh;
    padding: 0px 0px 0px 0px;
  }
  .organization-scroll-animation {
    width: 100%;
    height: 40vh;
  }
  .organization-scroll-text a {
    display: none;
  }
  .organization-scroll-text {
    width: 100%;
    position: absolute;
    left: 0%;
    display: flex;
    align-items: center;
  }
}
