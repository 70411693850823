:root {
  --video-height: 83vh;
}
.home-container {
  min-height: 100vh;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 90px 60px 60px 60px;
}

.video-info-two {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 14;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.video {
  height: var(--video-height) !important;
  display: flex;
  position: relative !important;
  border-bottom-left-radius: 80px !important;
  -webkit-border-bottom-left-radius: 80px !important;
  -moz-border-radius-bottomleft: 80px !important;
  -webkit-border-top-right-radius: 80px !important;
  -moz-border-radius-topright: 80px !important;
  border-top-right-radius: 80px !important;
}

.video-not-ready {
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: 
  linear-gradient(
      rgba(15, 23, 42, 0.8),
      rgba(15, 23, 42, 0.8)
    ),
    url("https://media.manyhands.charity/blur.png");
  background-size: cover;
  background-position: center;
  flex-direction: column;
  z-index: 15;
}

@keyframes slideInLeftToRight {
  0%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px); 
    opacity: 1;
  }
}

.white-arrow-down {
  margin-top: 40px;
  animation: slideInLeftToRight .8s ease-in-out infinite;
}

.video-block-none {
  display: none;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.heart-animation {
  display: inline-block;
  animation: heartbeat 2s infinite;
  font-size: 50px;
}

.extension-slide-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.extensiont-slide img {
  height: 55%;
  width: auto;
  margin-bottom: 15px;
}

.many-slide-wrapper {
  width: 100%;
  height: var(--video-height) !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 0px 0px 90px;
}

.many-slide-wrapper-charities {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.many-slide-wrapper h3 {
  margin: -10px 0px 5px 0px;
}

.charity-background-container {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0px 10px 10px 0px;
}

.many-slide-logo {
  max-width: 85%;
  max-height: 75%;
}

.extension-slide-mobile {
  display: none;
}

@media (max-width: 768px) {
  .home-container {
    min-height: 100vh;
    padding: 70px 30px 0px 30px;
  }
  .many-slide-wrapper {
    padding: 0px 0px 40px 60px;
  }
}

@media (max-width: 600px) {
  :root {
    --video-height: 80vh;
  }
  .home-container {
    min-height: var(--video-height);
    padding: 30px 30px 30px 30px;
  }
  .video {
    border-bottom-left-radius: 50px;
    -webkit-border-bottom-left-radius: 50px;
    -moz-border-radius-bottomleft: 50px;
    -webkit-border-top-right-radius: 50px;
    -moz-border-radius-topright: 50px;
    border-top-right-radius: 50px;
  }
  .video-info-two {
    height: var(--video-height);
  }
  .extension-slide-container {
    padding: 0px 10px;
  }
  .extension-slide-mobile {
    display: block;
  }
  .extension-slide-desktop {
    display: none;
  }
  .many-slide-wrapper {
    margin-top: 40px;
    padding: 0px 40px 0px 40px;
  }
  .charity-background-container {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 10px 5px 5px 0px;
  }
}
