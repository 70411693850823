.charity-lp-header-container {
  height: 100%;
  padding: 90px 60px 0px 60px;
  background-color: #f8fafc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charity-header-child-container {
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary);
  padding: 40px 0px;
  position: fixed;
  top: 80px;
}

.charity-header-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.charity-header-text-wrapper h1 {
  text-shadow: -4px -4px 0px var(--dark-green);
}

@keyframes slideInLeftToRight {
  0%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 1;
  }
}

.charity-header-white-arrow-down {
  margin-top: 40px;
  animation: slideInLeftToRight 0.8s ease-in-out infinite;
}

@media (min-width: 1500px) {
  .charity-header-child-container {
    width: 1500px;
  }
}
@media (max-width: 768px) {
  .charity-header-child-container {
    height: 40vh;
  }
  .charity-lp-header-container {
    padding: 0px 60px 0px 60px;
  }

  .charity-header-child-container {
    top: 30px;
  }
}
@media (max-width: 700px) {
  .charity-header-child-container {
    height: 50vh;
  }
  .charity-header-child-container {
    top: 0px;
  }
  .charity-header-white-arrow-down {
    height: 40px;
  }
}
