.small-organization-parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30vh; /* 40% of viewport height */
  max-height: 500px;
  position: relative;
}

/* Top wrapper */
.small-organization-wrapper-top {
  width: 100%;
  height: 50%;
  background-color: var(--color-secondary);
  max-height: 250px;
}

/* Bottom wrapper */
.small-organization-wrapper-bottom {
  width: 100%;
  height: 50%;
  background-color: #f8fafc;
  max-height: 250px;
}

/* Main content wrapper */
.small-organization-wrapper {
  width: calc(100% - 380px);
  max-width: calc(1500px - 380px);
  height: 100%;
  max-height: 400px;
  background-color: #f8fafc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0 60px 0px 60px;
  box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.15);
  border: 3px solid var(--dark-green);
  padding: 20px 80px;
}

/* Text container */
.small-organization-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.small-organization-text-container h3 {
  font-size: 35px;
  font-weight: 600;
  margin: 0px 20px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .small-organization-wrapper {
    width: calc(100% - 180px);
    max-width: calc(1500px - 180px);
    height: 35vh;
  }

  .small-organization-wrapper-top,
  .small-organization-wrapper-bottom {
    height: 17.5vh;
  }

  .small-organization-text-container h3 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .small-organization-wrapper {
    width: calc(100% - 60px);
    max-width: calc(1500px - 60px);
    height: 22vh;
    padding: 10px 20px;
  }

  .small-organization-wrapper-top,
  .small-organization-wrapper-bottom {
    height: 11vh;
  }

  .small-organization-text-container h3 {
    font-size: 25px;
  }
}

@media (max-width: 700px) {
  .small-organization-wrapper {
    width: 89%;
    height: 33vh;
    flex-direction: column;
    padding: 20px;
  }

  .small-organization-wrapper-top,
  .small-organization-wrapper-bottom {
    height: 16.5vh;
  }
}

@media (max-width: 500px) {
  .small-organization-parent{
    display: none;
  }
  .small-organization-wrapper {
    width: 89%;
    height: 37vh;
    flex-direction: column;
    padding: 20px;
  }

  .small-organization-wrapper-top,
  .small-organization-wrapper-bottom {
    height: 18.5vh;
  }
}
