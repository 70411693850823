.campaign-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.campaign-header-container {
  background-color: #fff;
  padding: 0px 20px;
  display: flex;
  width: 100%;
  border-bottom: 10px solid var(--dark-green);
}

.campaign-header-container h1 {
  font-size: 60px;
  text-shadow: -2px -2px 0px var(--dark-green);
}
.back-button-container h1 {
  font-size: 60px;
  text-shadow: -2px -2px 0px var(--dark-green);
}
.back-button-container h3 {
  font-size: 40px;
  font-weight: 200;
  color: var(--dark-green);
}
.back-button-container p {
  margin: 20px 30px 0 30px;
  font-size: 20px;
  font-weight: 400;
  color: var(--color-primary);
}
.email-steps {
  list-style-position: inside;
  text-align: left;
}
.email-steps li p {
  font-weight: 400;
  margin-bottom: 5px;
}
.campaign-header-select-header-text {
  font-size: 60px;
  font-weight: 100;
  text-shadow: none !important;
  margin-left: 20px;
  color: var(--dark-green);
}
.campaign-list {
  padding: 20px 0px;
}

.selected-campaign-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  min-height: 82vh;
  background-color: #fff;
}

.left-panel,
.right-panel {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-panel {
  width: 40%;
  border-right: 20px solid var(--color-primary);
}

.right-panel {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 2px solid var(--dark-green);
  background-color: rgb(232 236 240);
}

.right-panel-social-share {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.back-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.back-button {
  align-self: flex-start;
  font-size: 2rem;
}

.button-group {
  display: flex;
  gap: 8px;
  margin: 16px 0;
  justify-content: center;
}

.email-button {
  background-color: var(--color-primary);
  color: var(--dark-green);
}

.social-button {
  background-color: var(--color-primary);
  color: var(--dark-green);
}

.unselected-button {
  background-color: #e0e0e0;
  color: #9e9e9e;
}

.content-container {
  margin-top: 20px;
}

.social-share-template-container {
  border: 2px solid lightgrey;
  border-radius: 8px;
  padding: 16px;
  max-width: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  justify-content: space-between;
}

.social-share-template-text-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.social-share-template-text-container p {
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
}

.social-share-image-button-container {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
}

.social-share-icon-container {
 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0px 10px 0px;
}

.social-share-icon-button {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.template-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.template-text {
  text-align: left;
  line-height: 1.2;
  margin-bottom: 12px;
  font-size: 1rem;
  color: #333;
}

.template-link {
  text-decoration: none;
  color: var(--color-primary);
  font-weight: bold;
}

.email-card-wrapper {
  width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #ffffff;
  padding: 16px 0px;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 8px;
}

.email-card-content {
  position: absolute;
  top: 10px;
  pointer-events: none;
}

.campaign-email-builder-button-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 10px 0px;
}

.campaign-email-builder-button {
  height: 40px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.email-builder-button:hover {
  border: solid 1px var(--color-secondary);
}

.campaign-email-builder-button img {
  height: auto;
  width: auto;
  max-width: 50px;
  max-height: 30px;
}
