.dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-radius: 40px;
  margin-bottom: 25px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.question-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.text-container {
  width: 90%;
  display: flex;
  justify-content: flex-start;
}

.arrow-container {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.arrow-wrapper {
  display: flex;
  align-items: center;
}

.arrow {
  font-size: 30px;
  color: var(--color-secondary);
}
