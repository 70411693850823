.password-reset-container {
  width: 100%;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form-password-reset-container {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
