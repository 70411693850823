.organization-sign-up-container {
  width: 100%;
  display: flex;
  background-color: var(--dark-green);
}
.organization-sign-up-wrapper {
  height: var(--OneHundredVh);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-primary);
}

.organization-text-picture-wrapper {
  padding-top: 80px;
  height: var(--OneHundredVh);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: var(--dark-green);
  color: #fff;
}

.organization-text-picture-wrapper h2 {
  font-size: 55px;
  line-height: 1.2;
  font-weight: 600;
}
.organization-heart-one {
  height: auto;
  width: 82%;

  top: 85px;
  right: 20px;
}
.organization-heart-two {
  height: auto;
  width: 82%;

  bottom: 55px;
  right: 40px;
}

.organization-sign-up-textfield-wrapper {
  width: 50%;
}

.select-signup-wrapper {
  display: flex;
  flex-direction: column;
}

.organization-sign-up-wrapper h3 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 35px;
}
.upload-logo-container {
  margin-top: 20px;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.organization-sign-up-error {
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #fc4747;
  border-radius: 10px;
  font-size: 20px;
  margin: 10px 0px;
}

.organization-sign-up-error h3 {
  font-size: 20px;
  margin-bottom: 0px;
  color: #fff;
}

.organization-sign-up-error a h3 {
  color: #4565e6;
  text-decoration: underline;
  font-size: 20px;
}

.organization-sign-up-error a:hover h3 {
  color: var(--color-secondary);
  text-decoration: none;
}
.organization-logo-upload {
  margin-top: 10px;
  height: auto;
  max-height: 100px;
  width: 100px;
  background-color: #fff;
  padding: 4px;
}

.organization-logo-upload-placeholder {
  margin-top: 10px;
  height: 80px;
  width: 80px;
}
.organization-welcome-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.organization-welcome-login-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--dark-green);
}
.organization-welcome-login-text h2 h3 {
  color: var(--dark-green);
}
.organization-welcome-logo {
  width: 120px;
}

@media (max-width: 1024px) {
  .organization-text-picture-wrapper {
    display: none;
  }
  .organization-sign-up-wrapper {
    width: 100%;
  }
  .organization-sign-up-textfield-wrapper {
    width: 70%;
  }
  .select-signup-wrapper {
    width: 70%;
  }
  .upload-logo-container {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .select-signup-wrapper {
    display: flex;
    flex-direction: column;
  }
  .signup-select-right {
    margin-right: 0;
  }
  .signup-select-left {
    margin-left: 0;
  }
  .organization-logo-upload-placeholder {
    margin-top: 5px;
    height: 30px;
    width: 30px;
  }
  .organization-logo-upload {
    margin-top: 5px;
    height: auto;
    max-height: 30px;
    width: 30px;
  }
}
