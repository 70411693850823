:root {
  --color-primary: #0f172a;
  --color-secondary: #6b21a8;
  --white: #ffffff;
  --lite-green: #00ffa4;
  --dark-green: #00c7a4;
  --lite-gray: #e6e6e6;
  --dark-gray: #999999;
  --dark-dark-gray: rgb(51, 51, 51) 9;
  --font: "Barlow", sans-serif;
  --container-padding: 0px 190px 0px 190px;
  --OneHundredVh: 100vh;
  --EightyVh: 80vh;
  --SeventyVh: 70vh;
  --FortyVh: 40vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font);
  font-size: 16px;
  line-height: 1.5;
  background: #f8fafc;
  color: #333;
}

.desktop-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: #333;
}

ul {
  list-style: none;
}

li {
  line-height: 2;
}

h1,
h2,
h3,
h4,
p {
  line-height: 1.2;
  font-family: var(--font);
}

h1 {
  font-size: 125px;
  font-weight: 700;
  text-shadow: -4px -4px 0px var(--dark-green);
}

h2 {
  font-size: 75px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: -0.02em;
  margin-bottom: 0px;
}

h3 {
  font-size: 30px;
  font-weight: 500;
}

h4 {
  line-height: 1.4;
  font-size: 24px;
  font-weight: 300;
}

p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #333;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.parent-container {
  width: 100%;
  display: flex;
}

.logos-small {
  display: none;
}

/* Page Content */
.page-content {
  margin: 20px 0;
}

.page-content h2,
.page-content h3 {
  margin: 20px 0;
}

.page-content h2 {
  font-size: 40px;
}

.page-content h3 {
  font-size: 25px;
}

.mobile-menu {
  width: 300px;
  height: 100vh;
  background-color: var(--color-secondary);
  position: fixed;
  z-index: 1000;
  top: 0px;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.406) -3px 0px 15px;
}

.mobile-menu-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.close {
  height: 30px;
  width: 30px;
  position: relative;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 40px;
  height: 40px;
}

.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: var(--white);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

@media (max-height: 800px) {
  :root {
    --EightyVh: 665px;
    --SeventyVh: 665px;
    --FortyVh: 332px;
  }
}

@media (min-width: 1500px) {
  :root {
    --EightyVh: 600px;
    --SeventyVh: 600px;
  }
  body {
    display: flex;
    justify-content: center;
  }
  .desktop-wrap {
    width: 1500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
@media (max-width: 1024px) {
  :root {
    --container-padding: 0px 90px 0px 90px;
  }
  h2 {
    font-size: 3.5rem;
  }
}
@media (max-width: 960px) {
  .logos-small {
    display: block;
    margin: auto;
  }
  .logos {
    display: none;
  }
}

@media (max-width: 820px) {
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  h1 {
    font-size: 80px;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 25px;
  }
  p {
    line-height: 1.2;
  }
}

@media (max-width: 768px) {
  :root {
    --container-padding: 0px 30px 0px 30px;
  }
  h1 {
    text-shadow: -2.5px -2.5px 0px var(--dark-green) !important;
  }
}

@media (max-width: 500px) {
  :root {
    --container-padding: 0px 15px 0px 15px;
  }
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }
}
