.revenue-generated-container {
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  animation: AnimationName 10s ease infinite;
  background: linear-gradient(
    270deg,
    var(--color-secondary),
    var(--dark-green)
  );
  background-size: 400% 400%;
}
/* CLICK STYLING */
.click-generated-parent {
  /* padding-top: 50px; */
}

/* TEXT COMPONENT STYLE */
.purchase-generate-text h3 {
  padding-bottom: 1rem;
}

.tertiary-text h2 {
  margin-left: -4px;
  padding-bottom: 0.5rem;
}

/* PICTURE CONTAINER STYLING */
.click-generated-picture-wrapper {
  position: absolute;
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #f8fafc;
  border-bottom-left-radius: 60px !important;
  -webkit-border-bottom-left-radius: 60px !important;
  -moz-border-radius-bottomleft: 60px !important;
  -webkit-border-top-right-radius: 60px !important;
  -moz-border-radius-topright: 60px !important;
  border-top-right-radius: 60px !important;
  height: 275px;
}

.click-generated-image {
  position: absolute;
  width: 68%;
  bottom: 10px;
}
/* END OF CLICK STYLING */

/* PURCHASE STYLING */
.purchase-generated-parent {
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: row-reverse;
}

.purchase-generate-text-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px 80px;
}

/* PICTURE CONTAINER STYLING */
.purchase-generated-picture-wrapper {
  position: absolute;
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8fafc;
  border-bottom-left-radius: 60px !important;
  -webkit-border-bottom-left-radius: 60px !important;
  -moz-border-radius-bottomleft: 60px !important;
  -webkit-border-top-right-radius: 60px !important;
  -moz-border-radius-topright: 60px !important;
  border-top-right-radius: 60px !important;
  height: 275px;
}

.purchase-generated-image {
  position: absolute;
  width: 120%;
  bottom: 50px;
}

@media (max-width: 768px) {
  .purchase-generate-text-wrapper {
    padding: 0px 20px;
  }
  .click-generated-picture-wrapper {
    width: 85%;
  }
  .purchase-generated-picture-wrapper {
    width: 85%;
  }
}

@media (max-width: 600px) {
  .click-generated-picture-wrapper {
    width: 60%;
  }
  .purchase-generated-picture-wrapper {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .click-generated-picture-wrapper {
    width: 70%;
    height: 200px;
  }
  .click-generated-image {
    position: absolute;
    width: 48%;
    bottom: 10px;
  }
  .purchase-generated-picture-wrapper {
    width: 70%;
    height: 200px;
  }
  .purchase-generated-image {
    position: absolute;
    width: 100%;
    bottom: 25px;
  }
}

@media (max-width: 400px) {
  .click-generated-picture-wrapper {
    width: 90%;
    height: 200px;
  }
  .click-generated-image {
    position: absolute;
    width: 58%;
    bottom: 10px;
  }
  .purchase-generated-picture-wrapper {
    width: 90%;
    height: 200px;
  }
  .purchase-generated-image {
    position: absolute;
    width: 110%;
    bottom: 25px;
  }
}

