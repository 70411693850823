.demo-parent {
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  height: 100%;
  flex-direction: column;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
}
.demo-text-controls-container {
  display: flex;
}
.demo-controls-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
}
.demo-text-container {
  display: flex;
  flex-direction: column;
}
.demo-text-h2-h3-wrapper {
  display: flex;
  align-items: flex-end;
}
.demo-text-h2-h3-wrapper h3 {
  margin-left: 10px;
}

.demo-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  position: relative;
  z-index: 20;
  margin-top: -45px;
  padding: 0px 20px 0px 10px;
  background-color: var(--dark-green);
  border-bottom-left-radius: 21px;
  border-bottom-right-radius: 21px;
}
.demo-icon {
  color: #fff;
  margin: 0px 5px;
}
.demo-bottom-wrapper {
  margin-top: 35px;
}
.demo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  color: var(--dark-green);
  border: solid 2px var(--dark-green);
  font-size: 20px;
  height: 50px;
  margin: 0px 0px 4px 12px;
}
.demo-button-selected {
  background-color: #fff;
  color: var(--color-primary);
  border: solid 2px var(--dark-green);
}

@media (max-width: 1024px) {
  .demo-text-container {
    width: 60%;
  }
  .demo-controls-container {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .demo-text-h2-h3-wrapper h3 {
    font-size: 20px;
  }
  .demo-text-container h3 {
    font-size: 20px;
  }
}
@media (max-width: 700px) {
  .demo-text-container {
    width: 100%;
  }
  .demo-controls-container {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .demo-text-controls-container {
    flex-direction: column;
    text-align: center;
  }
  .demo-text-container {
    margin-bottom: 10px;
  }
  .demo-text-h2-h3-wrapper {
    justify-content: center;
  }
  .demo-button {
    height: 35px;
  }
}
