.charitySliderContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px 60px 0px;
  background-color: var(--color-primary);
}

.charityCardContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 60px 0px 0px 0px;
}

.text-wrap {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.non-profit-card-logo {
  max-width: 280px;
  max-height: 240px;
}

@media (max-width: 768px) {
  .charitySliderContainer {
    padding: 30px 0px 30px 0px;
  }
  .charityCardContainer {
    padding: 20px 0px 0px 0px;
  }
  .non-profit-card-logo {
    max-width: 180px;
    max-height: 140px;
  }
}
