.close-container {
    position: relative;
    height: 20px; /* this can be anything */
    width: 20px;  /* ...but maintain 1:1 aspect ratio */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.close-container::before,
.close-container::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1.5px;
    background-color: #fff;
}

.close-container::before {
    transform: rotate(45deg);
}

.close-container::after {
    transform: rotate(-45deg);
}