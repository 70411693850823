/* SLIDER CARD */
.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 370px;
  width: 370px;
  margin: 0 auto;
}

.card {
  display: block;
  position: absolute;
  max-width: 500px;
  max-height: 500px;
  cursor: pointer;
  will-change: transform, opacity;
  border-radius: 50%;
  height: 350px;
  width: 350px;
}

.front,
.back {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back {
  display: flex;
  justify-content: center;
  align-items: center;
}

.front {
  transform: translateX(-350px);
}

.charityCardInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -2px -3px 0px 0px var(--lite-green);
  background-color: var(--color-primary) !important;
  color: #fff;
  width: 150px;
  border-radius: 20px;
  padding: 5px 0px;
  border: 1px solid #fff;
}

@media (max-width: 768px) {
  .card {
    padding: 0px 20px;
    height: 220px !important;
    width: 220px !important;
  }
  .cardContainer {
    height: 240px;
    width: 240px;
  }
}

@media (max-width: 500px) {
  .card {
    padding: 0px 20px;
    height: 300px;
    width: 300px;
  }
}
