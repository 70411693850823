.tutorial-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tutorial-video-text-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  padding: 20px;
  margin-right: 20px;
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}
.tutorial-video-text-container h2 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 40px;
}
