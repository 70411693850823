.banner-container {
  position: relative;
  background-color: #f8fafc;
  color: rgb(194 200 210);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-text-container {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background: rgb(248, 250, 252);
  background: linear-gradient(
    90deg,
    rgba(248, 250, 252, 1) 7%,
    rgba(248, 250, 252, 1) 90%,
    rgba(248, 250, 252, 0) 100%
  );
  z-index: 20;
  padding: 20px 40px 20px 20px;
}
.fixed-text-container h2 {
  color: var(--dark-green);
}

.fixed-text-container-logo {
  width: 60px;
  margin: 10px 20px 0px 0px;
}
@media (max-width: 700px) {
  .fixed-text-container {
    padding: 10px 10px 10px 5px;
  }
  .fixed-text-container-logo {
    display: none;
  }
  .fixed-text-container h2 {
    font-size: 30px;
  }
  .banner-container h2 {
    font-size: 30px;
  }
}
