.button-ext-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 12rem;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  color: var(--color-secondary);
  border: solid 2px var(--color-secondary);
}

.button-ext-link-container:hover {
  background-color: var(--color-secondary);
  color: var(--dark-green);
  border: solid 2px var(--dark-green);
}

.button-ext-link-container a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;

  text-decoration: none;
  color: inherit;
}

.button-ext-link-container a:focus,
.button-ext-link-container a:hover {
  outline: none;
}

.button-ext-link-container p {
  padding: 0px !important;
  margin: 0px;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .button-ext-link-container {
    width: 175px;
    padding: 5px 10px;
    margin-top: 20px;
  }
}
@media (max-width: 600px) {
  .button-ext-link-container {
    margin-top: 15px;
  }
}
