.description-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: var(--container-padding);
  background-color: #f8fafc;
}
.description-container h3 {
  font-size: 35px;

  font-weight: 600;
  margin: 10px 0px;
}
.description-text-logo-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.custom-hr {
  border: none;
  height: 3px;
  background-color: #000;
  margin: 60px 0px 20px;
}

@media (max-width: 768px) {
  .description-container {
    padding: 0px 30px 0px 30px;
  }
  .description-container h3 {
    font-size: 28px;
    padding: 5px 0px;
    margin: 0px;
  }
}
@media (max-width: 600px) {
  .description-container h3 {
    font-size: 28px;
    margin: 5px 0px;
  }
  .custom-hr {
    margin: 30px 0px 20px;
  }
}
