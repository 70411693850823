/* CHarity page */
.charity-header-container {
  width: 100%;
  height: 40vh;
  display: flex;
  background: linear-gradient(270deg, #0f172a, #173b92);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
  padding: 120px 40px 40px 40px;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.charity-header-container-color {
  width: 100%;
  height: 40vh;
  display: flex;
  padding: 120px 40px 40px 40px;
}

.charity-child-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.charity-container {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  padding: 40px 60px;
}

.quote-description-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.charity-container-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 20px 60px;
}

.quote-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.quote-wrapper h3 {
  font-size: 27px;
}

.start {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.secondary h2 {
  height: 35px;
}
.dark {
  margin: 0px 0px 20px 0px;
}
.charity-data-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px 60px 20px 30px;
}
.charity-data-container-child {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  border: 0.5px solid #e0e0e0;
  padding-left: 20px;
}

.charity-data-container-child h4 {
font-size: 22px;
}

.charity-data-container-child p {
  margin: 0px 0px 20px 10px;
}

@media (max-width: 768px) {
  .charity-header-container-color {
    height: 25vh;
  }
  .charity-container {
    padding: 20px 20px;
  }
  .charity-container-child {
    padding: 20px;
  }
  .charity-data-container {
    padding: 20px;
  }
}
@media (max-width: 500px) {
  .charity-header-container-color {
    height: 35vh;
  }
  .quote-description-container {
    width: 100%;
  }
  .charity-data-container {
    width: 100%;
  }
  .dark {
    margin: 0px;
  }
}
