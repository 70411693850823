.parent-container {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 40px;
}

.child-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 1024px) {
  .parent-container {
    min-height: 60vh;
    flex-direction: column;
    padding: 60px 40px 40px 40px;
  }
  .child-container {
    align-items: center;
  }
}

@media (max-width: 500px) {
  .parent-container {
    padding: 60px 0px 40px 0px;
  }
}
