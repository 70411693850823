header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 60px 0px 60px;
  position: fixed;
  align-self: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: 110;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  background-color: #f8fafc;
  top: 0px;
}

.header-wrapper-desktop {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header-wrapper-mobile {
  display: none;
}
.header-logo-text {
  display: flex;
  align-items: center;
}

.active {
  top: 20px;
}

.gone {
  top: -120px;
}

.navbar-logo {
  height: 35px;
  vertical-align: middle;
}

.header h4 {
  font-size: 30px;
  font-weight: 500;
  margin: 0px 0px 0px 15px !important;
}

nav ul {
  display: flex;
  align-items: center;
}

li {
  padding: 0 10px;
}

a {
  font-size: 20px;
  color: var(--dark-green);
  font-family: var(--font);
  font-weight: 400;
}

.extensionLink {
  font-size: 20px;
  color: var(--white);
  font-family: var(--font);
  font-weight: 400;
  background-color: var(--color-secondary);
  padding: 5px 10px;
  border-radius: 25px;
  border: solid 2px var(--dark-green);
}

.extensionLink:hover {
  background-color: transparent;
  color: var(--dark-green);
  border: solid 2px var(--color-secondary);
}

@media (min-width: 1500px) {
  header {
    width: 1500px;
  }
}

@media (max-width: 1024px) {
  header {
    height: 60px;
    padding: 0px 30px 0px 30px;
  }
}

@media (max-width: 768px) {
  header {
    height: 60px;
    padding: 0px 20px 0px 20px;
  }
  .header h4 {
    display: none;
  }
  .extensionLink {
    font-size: 18px;
    color: var(--white);
    font-family: var(--font);
    font-weight: 400;
    background-color: var(--color-secondary);
    padding: 2.2px 5px;
    border-radius: 25px;
    border: solid 2px var(--dark-green);
  }
}

@media (max-width: 600px) {
  header {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: fixed;
    align-self: center;
    border-radius: 50%;
    top: 13px;
    left: 89%;
    z-index: 110;
    box-shadow: -4px -5px 0px 0px var(--color-primary);
    background-color: #fff;
    border: 4px solid var(--dark-green);
  }

  .header-wrapper-desktop {
    display: none;
  }

  .header-wrapper-mobile {
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-logo-mobile img {
    margin-top: 2px;
    height: 35px;
  }
  .extensionLink {
    display: none;
  }
}
