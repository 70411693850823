.revenue-parent {
  padding-top: 80px;
  padding-bottom: 110px;
  background-color: #f8fafc;
}

.revenue-text-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 50%;
}

.tertiary-text h3 {
  padding-bottom: 1rem;
}

.tertiary-text h2 {
  margin-left: -4px;
}

.tertiary-text p {
  padding-top: 0.6rem;
}

.revenue-container-picture-wrapper {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-primary);
  border-bottom-right-radius: 80px;
  border-top-left-radius: 80px;
  overflow: hidden;
  padding: 40px;
}

.contribution-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.contribution-table th,
.contribution-table td {
  border: 2.5px solid #ddd;
  padding: 8px;
  color: var(--dark-green);
  font-size: 18px;
}

.contribution-table th {
  background-color: #ffffff20;
  text-align: left;
  color: var(--dark-green);
}

.note {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: var(--dark-green);
}

.partner-supporters {
  background-color: #f00000;
  color: #000;
}

.partner-share {
  background-color: #f00000;
  color: #000;
}

@media (max-width: 768px) {
  .revenue-parent {
    padding-top: 20px;
    padding-bottom: 75px;
    flex-direction: column;
  }
  .revenue-text-container {
    width: 100%;
    margin-bottom: 30px;
  }
  .revenue-container-picture-wrapper {
    width: 100%;
  }
}

/* @media (max-width: 1024px) {
} */
/* @media (max-width: 500px) {
} */
