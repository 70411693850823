.organization-admin-container {
  height: 100vh;
}
.organization-admin-header {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: #f8fafc;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.organization-child-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
}

.organization-admin-logo {
  max-height: 40px;
  max-width: 320px;
  margin-right: 20px;
}

.organization-link-container {
  width: 100%;
  padding: 0px 30px;
  display: flex;
}

.link-child--graph-container {
  width: 100%;
  min-height: 65vh;
  max-height: 100%;
  padding-bottom: 10px;
  margin-right: 20px;
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}

.link-child--buttons-container {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-height: 65vh;
  max-height: 100%;
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  cursor: pointer;
}

.link-type-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 130px;
  border-radius: 20px;
  margin: 20px;
  color: rgb(132, 132, 132);
  padding: 10px 0px 0px 0px;
}

.email-builder-container {
  padding: 20px;
}

.email-builder-container h2 {
  font-size: 30px;
  color: var(--color-secondary);
}
.email-builder-container h3 {
  font-size: 22px;
  color: var(--color-secondary);
}

.email-builder-button-container {
  display: flex;
  padding: 30px 0px 20px 0px;
}
.email-builder-button {
  height: 80px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  border-radius: 20px;

  margin-right: 10px;
  /* border: solid 1px var(--color-secondary); */
}
.email-builder-button:hover {
  border: solid 1px var(--color-secondary);
}
.copy-builder-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 12rem;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  color: var(--color-secondary);
  border: solid 2px var(--color-secondary);
}

.copy-builder-button:hover {
  background-color: var(--color-secondary);
  color: var(--dark-green);
  border: solid 2px var(--dark-green);
}

.email-builder-button img {
  max-height: 60px;
  max-width: 100px;
}

@media (max-width: 500px) {
  .organization-child-container {
    background-size: 350px 350px;
  }
}
