.contact-page-container {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  background-color: #fff;
}
.contact-form-container {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px;
}

.meeting {
  background-color: #efefef;
}
.meeting img {
  width: auto;
  height: 250px;
  margin-bottom: 20px;
}
.form {
  background-color: #ffffff;
}
.text-container-contact-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message-sent-container {
  width: 100%;
  color: #fff;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
}

.success {
  background-color: var(--dark-green);
}

.error {
  background-color: #ff0000;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .contact-form-container {
    width: 80%;
  }
}
@media (max-width: 500px) {
  .contact-form-container {
    width: 100%;
  }
}
