.contact-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  background-color: var(--color-primary);
}

@media (max-width: 600px) {
  .contact-parent {
    text-align: center;
  }
}
