.partner-parent {
  background-color: #f8fafc;
  flex-direction: column;
  justify-content: center;
  padding-bottom: var(--padding-bottom);
}
.partner-text-wrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}
.partner-image {
  width: 80%;
  margin-bottom: 10px;
}
.partner-bottom-wrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .partner-image {
    width: 70%;
  }
  .partner-text-wrapper {
    width: 60%;
  }
  .partner-drop-down h3 {
    font-size: 25px;
  }
  .partner-drop-down h4 {
    font-size: 22px;
  }
}
@media (max-width: 700px) {
  .partner-image {
    width: 90%;
  }
  .partner-text-wrapper {
    width: 70%;
  }
}
