:root {
  /* --color-primary: #0f172a;
  --color-secondary: #6b21a8;
  --white: #ffffff;
  --lite-green: #00ffa4;
  --dark-green: #00c7a4;
  --lite-gray: #e6e6e6;
  --dark-gray: #999999;
  --dark-dark-gray: rgb(51, 51, 51) 9;
  --font: "Barlow", sans-serif; */
  /* --EightyVh: 80vh;
  --SeventyVh: 70vh; */
  --parent-component-container-padding: 0px 190px 0px 190px;
  --padding-top: 120px;
  --padding-bottom: 120px;
}

/* CONTAINER CLASSES */
.primary-component-container {
  width: 100%;
  display: flex;
  padding: var(--parent-component-container-padding);
}

.secondary-flex-80
  .secondary-flex-70
  .secondary-flex-60
  .secondary-flex-50
  .secondary-flex-40
  .secondary-flex-30 {
  display: flex;
}
.secondary-flex-100 {
  width: 100%;
}
.secondary-flex-80 {
  width: 80%;
}
.secondary-flex-70 {
  width: 70%;
}
.secondary-flex-60 {
  width: 60%;
}

.secondary-flex-50 {
  width: 50%;
}

.secondary-flex-40 {
  width: 40%;
}
.secondary-flex-30 {
  width: 30%;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
}

.tertiary-text {
  display: flex;
  justify-content: center;
}

/* TEXT CLASSES */
.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

/* MARGIN TOP */
.landing-page-body-container {
  z-index: 10;
  margin-top: 65vh;
}

/* MEDIA QUERIES */
@media (max-width: 1024px) {
  :root {
    --parent-component-container-padding: 0px 90px 0px 90px;
    --padding-top: 100px;
    --padding-bottom: 100px;
  }
}

@media (max-width: 768px) {
  :root {
    --parent-component-container-padding: 0px 30px 0px 30px;
  }
  .landing-page-body-container {
    margin-top: 40vh;
  }
}

@media (max-width: 700px) {
  :root {
    --parent-component-container-padding: 0px 20px 0px 20px;
    --padding-top: 45px;
    --padding-bottom: 45px;
  }
  .primary-component-container {
    flex-direction: column !important;
  }
  .secondary-flex-80 {
    width: 100%;
  }
  .secondary-flex-70 {
    width: 100%;
  }
  .secondary-flex-60 {
    width: 100%;
  }

  .secondary-flex-50 {
    width: 100%;
  }

  .secondary-flex-40 {
    width: 100%;
  }
  .secondary-flex-30 {
    width: 100%;
  }
  .top-secondary-flex {
    margin-bottom: 30px;
  }
  .tertiary-text {
    align-items: center;
    text-align: center;
  }
}
