.myComponentContainer {
  display: flex;
  height: var(--EightyVh);
  background: linear-gradient(
    270deg,
    var(--color-secondary),
    var(--dark-green)
  );
  background-size: 400% 400%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
  padding: var(--container-padding);
}

.leftDiv,
.rightDiv {
  width: 50%;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 40px;
  text-align: left;
}

.rightDiv h2 {
  color: #fff;
}

.rightDiv .rightDivText {
  color: #fff;
  padding-top: 2rem;
}

.leftDiv {
  display: flex;
  align-items: center;
}

.childOfLeftDiv {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 450px;
  border-bottom-right-radius: 80px;
  border-top-left-radius: 80px;
  overflow: hidden;
}

.imageFullWidth {
  position: relative;
  width: 600px !important;
  top: 90px;
  height: auto;
}
@media (max-width: 768px) {
  .myComponentContainer {
    height: 50vh;
  }
  .childOfLeftDiv {
    width: 275px;
    height: 300px;
  }
  .rightDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    padding: 0px;
    text-align: left;
  }
}
@media (max-width: 600px) {
  .myComponentContainer {
    height: 100%;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .leftDiv,
  .rightDiv {
    width: 100%;
  }
  .leftDiv{
    align-items: center;
    justify-content: center;
  }
  .childOfLeftDiv {
    width: 80%;
    height: 250px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
   margin-bottom: 30px;
  }
  .rightDiv .rightDivText {
    color: #fff;
    padding-top: 1rem;
  }
  .rightDiv {
    text-align: center;
    align-items: center;
  }
}
