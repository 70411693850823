.update-organization-container {
  margin-left: 30px;
  width: 65%;
  min-height: 65vh;
  max-height: 85vh;
  padding: 20px 40px;
  margin-right: 20px;
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}

.organization-payment-container {
  margin-left: 30px;
  width: 65%;
  min-height: 65vh;
  max-height: 85vh;
  padding: 20px 40px;
  margin-right: 20px;
  background-color: #f8fafc;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  overflow: scroll;
}

.update-organization-updated {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.update-organization-updated-logo {
  max-width: 150px;
  max-height: 150px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.update-organization-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: left;
}
.update-organization-container h2 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 40px;
}

.organization-payment-container h2{
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 40px;
}

.upload-logo-container-update {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.upload-logo-container-update-admin {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /* flex-direction: column; */
}
.organization-sign-up-error {
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #f00000;
  border-radius: 10px;
  font-size: 20px;
}

.organization-sign-up-error h3 {
  font-size: 20px;
  margin-bottom: 0px;
  color: #fff;
}

.organization-sign-up-error a h3 {
  color: #4565e6;
  text-decoration: underline;
  font-size: 20px;
}

.organization-sign-up-error a:hover h3 {
  color: var(--color-secondary);
  text-decoration: none;
}
.organization-logo-upload {
  padding: 10px 0px;
  height: auto;
  max-height: 80px;
  width: 80px;
}

.organization-logo-upload-admin{
  padding: 0px 20px;
  height: auto;
  max-height: 80px;
  width: 150px;
}

.organization-logo-upload-placeholder-admin {
  /* margin-top: 10px; */
  height: 80px;
  width: 80px;
}

.organization-logo-upload-placeholder {
  margin-top: 10px;
  height: 80px;
  width: 80px;
}
.organization-welcome-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.organization-welcome-login-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--dark-green);
}
.organization-welcome-login-text h2 h3 {
  color: var(--dark-green);
}
.organization-welcome-logo {
  width: 120px;
}
